import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from '../components/layout'
import BackgroundImage from 'gatsby-background-image'
import { graphql } from 'gatsby'

import "../sass/main.scss"

const PalomaPage = ({ data }) => (
  <Layout>
  <>
        <GatsbySeo
        	title='Psicóloga integradora - Paloma Navascués'
      		description='Soy Paloma Navascues, una psicologa online especializada en terapia integradora para personas migrantes y expatriadas hispanohablantes'
     		noindex={true}
     		nofollow={true}
        >
        </GatsbySeo>
    </>
    {/* <!-- Wrapper --> */}
			<div id="wrapper" className="divided">

				{/* <!-- Hero --> */}
					<section className="banner style1 orient-left content-align-left image-position-center fullscreen onload-image-fade-in onload-content-fade-right">
						<div className="content">
							<h1>Psicóloga online integradora</h1>
							<p className="major">Más de diez años dedicándome a la psicología desde diferentes ámbitos y áreas.</p>
							<ul className="actions stacked fit">
								<li><a href="#appointment" className="button primary">Solicita una sesión informativa</a></li>
							</ul>
							<p>* o contactame a través de <a href="https://wa.me/34644811385" className="icon style2 fa-whatsapp"><span className="label">Whatsapp</span></a> <a href="https://m.me/hellovandla" className="icon style2 fa-facebook"><span className="label">Facebook Messenger</span></a></p>
						</div>
				        <BackgroundImage
				          className="image"
				          fluid={data.heroFluidImage.childImageSharp.fluid}
				          backgroundColor={`#dfdfdf`}
				        >
				        </BackgroundImage>
					</section>

				{/* <!-- Spotlight --> */}
					<section className="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in" id="first">
						<div className="content">
							<h2>Mi viaje por la psicología</h2>
							<p>Mi recorrido como <strong>psicóloga</strong> se ha ido enriqueciendo al mismo tiempo que mi camino vital.</p>
							<p>He tenido la oportunidad de ejercer en <strong>diferentes áreas de la psicología</strong> y esto me ha aportado una <strong>visión global</strong> y poliédrica de la persona. Desde el aspecto más físico y corporal de las <strong>emociones</strong>, hasta los <strong>desafíos sociales y personales</strong> que implican una migración.</p>
							<p>Mis últimos años viviendo en el extranjero me han permitido integrar los conocimientos adquiridos a lo largo de mis años de experiencia y <strong>apreciar a la persona desde todos sus ángulos y facetas</strong>.</p>
						</div>
				        <BackgroundImage
				          className="image"
				          fluid={data.feat1FluidImage.childImageSharp.fluid}
				          backgroundColor={`#dfdfdf`}
				        >
				        </BackgroundImage>
					</section>

				{/* <!-- Spotlight --> */}
					<section className="spotlight style1 orient-left content-align-left image-position-center onscroll-image-fade-in" id="second">
						<div className="content">
							<h2>Cómo trabajo</h2>
							<p>Mi enfoque de trabajo es <strong>esencialmente integrador</strong>:</p>
							<p>Desde la base de la que parto, entiendo las <strong>emociones</strong> que siente la persona en el presente como reflejo de los esquemas de pensamiento y de relaciones que se ha ido formando a lo largo de sus <strong>experiencias vitales</strong> confluyendo con sus circunstancias actuales.</p>
							<p>Trabajo con la persona esas narrativas y sus emociones ancladas siempre con respeto y aceptación incondicional y, cuando pueden ser convenientes, incorporo técnicas rápidas y efectivas como <strong>EMDR</strong> y herramientas complementarias como <strong>Mindfulness y Fotografía Terapéutica</strong>.</p>
						</div>
				        <BackgroundImage
				          className="image"
				          fluid={data.feat2FluidImage.childImageSharp.fluid}
				          backgroundColor={`#dfdfdf`}
				        >
				        </BackgroundImage>
					</section>

				{/* <!-- Spotlight --> */}
					<section className="wrapper style2 color1" id="blockquote">
						<div className="inner">
							<h2>Historias reales</h2>
							<blockquote>En medio de una crisis severa llegue a Paloma y puedo decir que gracias a ella, su interés y empatía, no solo he salido de la fatal crisis, sino que, también, finalmente entiendo y acepto quién soy, me siento cómoda en mi piel, con las personas que me rodean y que estoy en el camino adecuado para estar mejor.</blockquote>
							<h3>- María</h3>
						</div>
					</section>						

				{/* <!-- Spotlight --> */}
					<section className="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in" id="third">
						<div className="content">
							<h2>Algunos apuntes</h2>
							<p>Mis experiencias profesionales de los últimos años, además de en mi consulta privada, han sido:</p>
							<ul>
								<li>En diferentes asociaciones de <strong>atención a migrantes</strong> hispanohablantes en Berlín como psicóloga atendiendo individual y grupalmente.</li>
								<li>He participado en el V y VI <strong>Congreso Internacional de Migración y Salud Mental</strong> organizado por la Red Atenea con la ponencia sobre el estudio piloto: Cambio de identidad en el proceso migratorio.</li>
								<li>También compagino mi actividad con proyectos en España, como el realizado en un Punto Municipal del Observatorio Regional de Violencia de Género en el que trabajo con fotografías cuestiones de <strong>género y relaciones de buen trato</strong>.</li>
							</ul>
						</div>
				        <BackgroundImage
				          className="image"
				          fluid={data.feat3FluidImage.childImageSharp.fluid}
				          backgroundColor={`#dfdfdf`}
				        >
				        </BackgroundImage>
					</section>				

				{/* <!-- Form --> */}
					<section className="wrapper style2 align-center" id="appointment">
											<div className="inner">
						<header>
							<h3>Primera cita informativa gratis</h3>
							<p>Completa los campos del formulario para solicitar una cita y me pondre en contacto contigo a la mayor brevedad posible o si lo prefieres contáctame por Whatsapp <strong><a href="https://wa.me/34644811385" className="icon style1 fa-whatsapp"><span className="label">Twitter</span></a></strong> o por Messenger <a href="https://m.me/hellovandla" className="icon style1 fa-facebook"><span className="label">Facebook Messenger</span></a></p>
						</header>
						<div className="content">

											<form action="/success" id ="appointment" name="appointment" method="post" netlify-honeypot="bot-field" data-netlify="true">
                        					<input type="hidden" name="form-name" value="appointment" />
                        					<input type="hidden" name="bot-field" />
												<div className="fields">
													<div className="field third">
														<label for="name">Nombre*</label>
														<input type="text" name="name" placeholder="tu nombre completo" required/>
													</div>
													<div className="field third">
														<label for="email">Email*</label>
														<input type="email" name="email" placeholder="tu@email.com" required/>
													</div>
													<div className="field third">
														<label for="whatsapp">Whatsapp*</label>
														<input type="text" name="whatsapp" placeholder="+34 123 123 123" required/>
													</div>
													<div className="field align-left">
														<input type="checkbox" id="consent" name="consent" required/>
														<label for="consent">Acepto el tratamiento de los datos de acuerdo a las condiciones generales y tratamiento de los datos personales.</label>
													</div>
												</div>
												<ul className="actions">
													<li><input type="submit" name="submit" id="submit" value="Solicitar cita" /></li>
												</ul>
											</form>

						</div>
						</div>
				</section>

				{/* <!-- Footer --> */}
		          <footer className="wrapper style1 align-center">
		            <div className="inner">
		              <ul className="icons">
		                <li><a href="https://wa.me/34644811385" className="icon style2 fa-whatsapp"><span className="label">Twitter</span></a></li>
		                <li><a href="https://m.me/hellovandla" className="icon style2 fa-facebook"><span className="label">Facebook</span></a></li>
		                <li><a href="mailto:hello@vandla.com" className="icon style2 fa-envelope"><span className="label">Email</span></a></li>
		              </ul>
		              <p>&copy; 2020 Paloma Navascués | <a href="https://vandla.com/politica-privacidad/" rel="nofollow">Politica de Privacidad</a> | Design: <a href="https://html5up.net" rel="nofollow">HTML5 UP</a>.</p>
		            </div>
		          </footer>					

			</div>
  </Layout>
)

export default PalomaPage


export const pageQuery = graphql`
  query {
    heroFluidImage: file(relativePath: { eq: "psicologa-paloma-navascues.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 650, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    feat1FluidImage: file(relativePath: { eq: "brujula.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 650, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    } 
    feat2FluidImage: file(relativePath: { eq: "piedras-apiladas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 650, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }   
    feat3FluidImage: file(relativePath: { eq: "taller-mindfulness-fotografia.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, maxHeight: 650, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }                    
  }
`
